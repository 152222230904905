import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Credit Risk Transformation "
const pageTitle = "Credit Risk Transformation"
const subTitle = "Modernization"
const para = `The Market Data, Trade Data and Reference 
  Data used to build these feeds and valuations 
  are collected from various golden sources.
  The market data keeps changing during the 
  course of the day – this makes capturing a 
  snapshot of the market data in the current 
  system difficult - this in turn impacts the ability 
  to generate risk feeds and valuations for 
  decision making in a timely manner.`
const blockquote = `"Application Modernization for a Credit Risk Valuation and Feed generation system."`

const cardItems = [
  {
    id: 1,
    heading: `Solution`,
    listItems: (
      <>
        <li><Icon.Square />
          Use Data Services to help reduce time to load data from Golden
          Sources. Dedicated services interact with Data Sources to constantly
          fetch data from data sources and version them.
        </li>
        <li><Icon.Square />
          Since upstream systems cannot preload market data based on the
          previous days feeds, snapshots of these market data are used for Risk
          Calculation to create an accurate Risk Valuation and Risk feed.
        </li>
        <li><Icon.Square />This is done based as a solution leveraging Microservices.</li>
      </>
    ),
  },

  {
    id: 2,
    heading: `Technical Solution`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Microservices are now responsible for fetching Market data from
          various sources. During peak time, Microservices can be automatically
          scaled to handle the work load and scaled back during off peak hours.
        </li>
        <li>
          <Icon.Square />
          The system built is Cloud ready and deployed on a Private Cloud
          Platform (RedHat OpenShift).
        </li>
        <li>
          <Icon.Square />
          Built an Analytic Engine for breaking a large book of trade into
          separate tasks – process these tasks gathering information from
          various sources and provide a timely Risk Calculation.
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Technical Implementation`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          DevOps and CI/CD principles for zero down time for deployments.
        </li>
        <li>
          <Icon.Square />
          The Atyeti team designed an event driven architecture for the
          subscription and registration of data.
        </li>
        <li>
          <Icon.Square />
          The Risk calculation process was refactored out of the application and
          built as an independently deployable and scalable unit.
        </li>
        <li>
          <Icon.Square />
          Build the application Cloud ready and Cloud Agnostic.
        </li>
      </>
    ),
  },
  {
    id: 4,
    heading: `Microservices`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          Built the application using Microservices for Scalability, Resilience
          and Fault tolerance.
        </li>
        <li>
          <Icon.Square />
          Using MongoDB ( NoSQL DB) to store and fetch data resulted in faster
          retrieval in msecs.
        </li>
        <li>
          <Icon.Square />
          Microservices deployed in OpenShift containers.
        </li>
        <li>
          <Icon.Square />
          Event Driven design using EMS.
        </li>
        <li>
          <Icon.Square />
          Deployed CI/CD using Jenkins.
        </li>
        <li>
          <Icon.Square />
          Deployed Industry standards and best practices in developing Cloud
          ready solution.
        </li>
      </>
    ),
  },
  {
    id: 5,
    heading: `Core Technologies`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          OpenShift (Docker/Kubernetes)
        </li>
        <li>
          <Icon.Square />
          MongoDB
        </li>
        <li>
          <Icon.Square />
          .Net Microservices
        </li>
        <li>
          <Icon.Square />
          Splunk
        </li>
        <li>
          <Icon.Square />
          Prometheus
        </li>
        <li>
          <Icon.Square />
          Grafana
        </li>
        <li>
          <Icon.Square />
          TIBCO EMS
        </li>
      </>
    ),
  },
  {
    id: 6,
    heading: `Result`,
    listItems: (
      <>
        <li>
          The Application can now generate Risk feeds in near Real- Time and is
          one step closer to support T0 processing and Intra-Day Risk valuation.
        </li>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query FirstQuery {
    casedetailsJson(slug: { eq: "credit-risk-transformation" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
